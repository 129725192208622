import { DataView24, Edit24 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card/Card";
import { AuthContext } from "../../contexts/AuthContext";
import http from "../../http-common";

const CompetitorDashboardPage = () => {
  const { user, isCompetitor } = useContext(AuthContext);

  const [application, setApplication] = useState(null);

  const navigate = useNavigate();

  const loadApplication = async () => {
    try {
      const response = await http.get(`/applications/phone/${user.username}`);
      setApplication(response.data);
    } catch (error) {}
  };

  const handlCreate = () => {
    navigate("../registar-candidatura", { replace: true });
  };
  const handlUpdate = () => {
    navigate("../actualizar-candidatura/" + application.uuid, {
      replace: true,
    });
  };

  useEffect(() => {
    loadApplication();
  }, []);

  return (
    <Card title={"Olá " + user.firstName + " " + user.lastName}>
      {application ? (
        <div>
          <div className="view-header">
            <div>
              <p>
                <strong>Número de Telefone: </strong>
                {application?.phoneNumber1}
              </p>
              <p>
                <strong>Estado da Candidatura: </strong>
                {application?.currentStageDesc}
              </p>
            </div>
          </div>
          {application?.currentStageCode === 1 ? (
            <Button
              renderIcon={Edit24}
              iconDescription="Editar"
              onClick={() =>
                navigate("../editar-candidatura/" + application.id)
              }
            >
              Editar
            </Button>
          ) : (
            <Button
              renderIcon={DataView24}
              iconDescription="Consultar"
              onClick={() =>
                navigate("../visualizar-candidatura/" + application.id)
              }
            >
              Consultar
            </Button>
          )}
        </div>
      ) : (
        <div>
          <h5>Regista a tua candidatura até ao dia 30/04/2022</h5>
          <p className="mt-1">
            - Clica no botão Registar Candidatura para abrires o formulário de
            candidatura
          </p>
          <p>
            - Podes registar a candidatura em partes, salvando no fim de cada
            actualização
          </p>
          <p>
            - Quando estiveres satisfeito com o registo, submeta. Mas atenção,
            uma vez submetida, a candidatura não pode ser alterada
          </p>
          <Button className="mt-2" onClick={handlCreate}>
            Registar a Candidatura
          </Button>
        </div>
      )}
    </Card>
  );
};

export default CompetitorDashboardPage;
